<template>
    <v-dialog persistent fullscreen v-model="show">
        <v-card :loading="loading" :disabled="loading">
            <!-- in ios add pt-12 instead of pt-4 -->
            <v-card-text class="d-flex align-center justify-space-between"
            :class="$ios? 'pt-12': 'pt-4'"
            >
                <div class="dialog-title">{{ $tr("Change Language") }}</div>
                <v-btn text icon :disabled="loading" @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>

            <v-list subheader flat>
                <v-subheader class="text-caption center">{{ $tr("Languages") }}:</v-subheader>
                <v-divider></v-divider>
                <v-list-item-group>
                    <template v-for="(language, index) of languages">
                        <v-list-item
                            :key="index"
                            :disabled="language.value == $store.getters['translate/current_language'].value"
                            :class="
                                language.value == $store.getters['translate/current_language'].value ? 'active' : ''
                            "
                        >
                            <v-list-item-content >
                                <v-list-item-title
                                
                            @click="change_language(language.value)"
                                >{{ language.text }}</v-list-item-title>
                            </v-list-item-content>
                            <v-spacer></v-spacer>

                            <v-list-item-action>
                                <v-btn icon @click="change_language(language.value, true)"> <v-icon>mdi-reload</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="`divider-${index}`"></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            valid: false,
            loading: false,
            languages: [],
        };
    },
    models: [{ data: "show", event: "models:show" }],
    watch: {
        async show(v) {
            this.$emit("models:show", v);
            if (v) {
                console.log("refreshing");
                await this.refresh();
            }
        },
    },
    methods: {
        async change_language(lk, now) {
            this.loading = true;
            try {
                await this.$store.dispatch("translate/change_language", {
                    key: lk, 
                    now: now, 
                }, { root: true });
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        async refresh() {
            this.loading = true;
            try {
                if (this.languages.length == 0) {
                    this.languages.push(
                        ...(await this.$api.post("translate/fetch/available_language_packs", null, {
                            sinceMins: 4320
                        })).data.languages_packs
                    );
                }
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
    },
    computed: {},
};
</script>

<style lang="sass">
.dialog-title
    font-size: 24px
    font-weight: 700
.active
    background-color: #00004423
</style>
